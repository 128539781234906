import { Icon } from '/features/buildingBlocks/Icon'
import { Tag } from '/features/buildingBlocks/Tag'

import styles from './EventCard.css'

import ticketIcon from '/images/icons/ticket.raw.svg'

export function EventCard({ item, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.iconContainer}>
        <Icon icon={ticketIcon} layoutClassName={styles.iconLayout} />
      </div>

      <div className={styles.infoContainer}>
        <h4 className={styles.title}>{item.title}</h4>
        <Tag label={item.date} colorScheme='white' layoutClassName={styles.tagLayout} />
      </div>
    </div>
  )
}
