import { animated } from '@react-spring/web'
import { useParallax } from '/machinery/useParallax'

import { Video } from '/features/buildingBlocks/Video'

import styles from './VideoCard.css'

export function VideoCard({ item, layoutClassName = undefined }) {
  const { url, poster, title } = item
  const distance = 5
  const { parallaxRef, parallaxStyle } = useParallax({ distance, springConfig: { mass: 1, tension: 200, friction: 25 } })

  return (
    <div ref={parallaxRef} className={cx(styles.component, layoutClassName)}>
      <animated.div style={{ ...parallaxStyle }}>
        <Video {...{ url, title, poster }} />
      </animated.div>
    </div>
  )
}
