import { animated } from '@react-spring/web'
import { useParallax } from '/machinery/useParallax'
import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './QuoteCard.css'

export function QuoteCard({ item, layoutClassName = undefined }) {
  const { parallaxRef, parallaxStyle } = useParallax({ distance: 15, springConfig: { mass: 1, tension: 300, friction: 40 } })

  return (
    <div ref={parallaxRef} className={cx(styles.component, layoutClassName)}>
      <div className={styles.textContainer}>
        <q className={styles.quote}>{item.quote}</q>
      </div>

      <animated.div className={styles.imageContainer} style={parallaxStyle}>
        <ImageCover image={item.portrait} aspectRatio={5 / 7} layoutClassName={styles.imageLayout} />
      </animated.div>
    </div>
  )
}
