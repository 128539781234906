import { animated } from '@react-spring/web'
import { useParallax } from '/machinery/useParallax'
import { ImageCover } from '/features/buildingBlocks/Image'
import { TagWithIcon } from '/features/buildingBlocks/Tag'

import styles from './ImageCard.css'

import instagramIcon from '/images/icons/instagram.raw.svg'

export function ImageCard({ item, layoutClassName = undefined }) {
  const { image, aspectRatio, label } = item
  const { parallaxRef, parallaxStyle } = useParallax({ distance: 10, springConfig: { mass: 1, tension: 200, friction: 50 } })

  return (
    <div ref={parallaxRef} className={cx(styles.component, layoutClassName, aspectRatio === '5/3' && styles.landscape)}>
      {label && (
        <div className={styles.tagContainer}>
          <TagWithIcon icon={instagramIcon} colorScheme='white' layoutClassName={styles.tagLayout} {...{ label }} />
        </div>
      )}

      <animated.div style={parallaxStyle}>
        {image && <ImageCover aspectRatio={aspectRatio === '5/3' ? 5 / 3 : 1} {...{ image }} />}
      </animated.div>
    </div>
  )
}
